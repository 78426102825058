<template>
  <div class="mt-8">
    <div class="d-flex mb-2">
      <label class="text-field-label">Time Slots</label>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="appbar__btn"
        height="35px"
        @click="createItem()"
        v-if="permission"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Add Time Slot
      </v-btn>
    </div>
    <div
      class="mx-md-4"
      id="block-time-slot-list"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-data-table
        :headers="headers"
        :items="timeSlotList"
        :options.sync="options"
        :loading="loading"
        :items-per-page="10"
        :server-items-length="blockTimeSlotMeta.total"
        :footer-props="{
          'items-per-page-options': [5],
        }"
        @update:options="fetchBlockTimeSlotList"
        :sort-by.sync="timeslotDefaultFilter"
        :sort-desc.sync="sortOrder"
        :no-data-text="noDataText"
      >
        <template v-slot:item.start_time="{ item }">
          {{ formatDate(item.start_time) }}
        </template>
        <template v-slot:item.start="{ item }">
          {{ formatTime(item.start_time) }}
        </template>
        <template v-slot:item.end_time="{ item }">
          {{ formatTime(item.end_time) }}
        </template>
        <template v-slot:item.attendees="{ item }">
          <div
            class="link"
            style="color: blue"
            @click="openAttendeesModal(item)"
          >
            {{ limit ? `${item.attendees}/${limit}` : item.attendees }}
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            class="me-2"
            size="default"
            v-if="permission"
            @click="editItem(item)"
            >{{ icons.edit }}</v-icon
          >
          <v-icon size="default" v-if="permission" @click="deleteItem(item)">{{
            icons.delete
          }}</v-icon>
        </template>
        <template #no-data>
          {{ noDataText }}
        </template>
      </v-data-table>
      <v-btn
        variant="text"
        class="show-hide-slots-btn"
        @click="toggleShowHidePastSlots"
        :loading="loading"
      >
        <v-icon>{{ showAllSlots ? icons.eyesOn : icons.eyesOff }}</v-icon>
        <span class="primary--text ml-2">Show/Hide Past Slots</span>
      </v-btn>
    </div>

    <v-dialog v-model="dialogBlockSlotTime" max-width="600px">
      <v-form
        ref="formCreateBlockTimeSlot"
        class="bookable-block-time-slot-form__form"
        @submit.prevent="submitCreate"
      >
        <input type="hidden" :value="formBlockTimeSlot.id" name="id" />
        <v-card class="px-2 py-2">
          <v-card-title>
            <span class="text-h5 ms-3"
              >{{ formBlockTimeSlot.id ? 'Edit' : 'New' }} Time Slot</span
            >
          </v-card-title>
          <v-card-text class="pb-0 mb-6">
            <v-container class="pb-0">
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <DatetimePicker
                    v-model="formBlockTimeSlot.date"
                    flat
                    solo
                    required
                    type="date"
                    placeholder="Date"
                    hide-details="auto"
                    :showCalendarIcon="true"
                    :dateFormat="isoFormat"
                    :error-messages="formBlockTimeSlot.$getError('date')"
                    :loading="formBlockTimeSlot.$busy"
                    :disabled="formBlockTimeSlot.$busy"
                    @input="handleDateChange"
                    :valueZone="timeZone"
                  />
                </v-col>
                <v-col cols="6" sm="6" md="3">
                  <DatetimePicker
                    flat
                    solo
                    type="time"
                    placeholder="Start"
                    hide-details
                    :value="formBlockTimeSlot.start_time"
                    :error-messages="formBlockTimeSlot.$getError('start_time')"
                    :loading="formBlockTimeSlot.$busy"
                    :disabled="formBlockTimeSlot.$busy"
                    @input="handleStartChange"
                    :valueZone="timeZone"
                  />
                </v-col>
                <v-col cols="6" sm="6" md="3">
                  <DatetimePicker
                    flat
                    solo
                    type="time"
                    placeholder="End"
                    hide-details
                    :value="formBlockTimeSlot.end_time"
                    :error-messages="formBlockTimeSlot.$getError('end_time')"
                    :loading="formBlockTimeSlot.$busy"
                    :disabled="formBlockTimeSlot.$busy"
                    @input="handleEndChange"
                    :valueZone="timeZone"
                  />
                </v-col>
              </v-row>
            </v-container>
            <div class="ml-4 mt-4 red--text" v-if="newTimeSlotIsOverlap">
              Warning: This time slot conflicts with an existing time slot
            </div>
            <div class="ml-4 mt-4 red--text" v-if="!isValidTime">
              The end date should be after start date.
            </div>
            <div
              class="ml-4 mt-4 red--text"
              v-if="!isValidDate || !isValidUpcomingTime"
            >
              The start date should be today or after today.
            </div>
          </v-card-text>
          <v-card-actions class="pb-5 pt-0">
            <v-spacer></v-spacer>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 "
              text
              :disabled="loading"
              @click="cancelDialog()"
            >
              Cancel
            </v-btn>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 text-uppercase"
              text
              name="submitCreateBlockSlotItem"
              :loading="formBlockTimeSlot.$busy"
              :disabled="
                loading ||
                !isValidTime ||
                !isValidDate ||
                !isValidUpcomingTime ||
                !formBlockTimeSlot.start_time ||
                !formBlockTimeSlot.end_time
              "
              @click="
                formBlockTimeSlot.id
                  ? handleShowWarningActionTimeSlot()
                  : checkTimeSlot()
              "
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogDeleteBlockSlotTime" max-width="600px">
      <v-form
        ref="formDeleteBlockTimeSlot"
        class="faq-form__form"
        @submit.prevent="handleShowWarningActionTimeSlot"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Are you sure to delete this time slot?</span>
          </v-card-title>
          <v-card-actions class="pb-5">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelDialogDelete()">
              No
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="handleShowWarningActionTimeSlot"
              name="handleShowWarningActionTimeSlot"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="showWarningActionTimeSlot" max-width="500px">
      <v-card color="white">
        <v-card-title>
          <span
            class="delete-attendee-warning red--text text-center text-wrap"
            style="word-break: break-word"
            >Warning: You are about to
            {{ typeActionTimeSlot === 'Update' ? 'update' : 'delete' }} a time
            slot that has registered attendees.
            <br />
            Please confirm you would like to proceed.</span
          >
        </v-card-title>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            color="black--text darken-1"
            @click="handleHiddenWarningActionTimeSlot()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="red white--text darken-1 font-weight-bold"
            @click="
              typeActionTimeSlot === 'Update'
                ? checkTimeSlot()
                : submitDeleteBlockTimeSlot()
            "
            :name="
              typeActionTimeSlot === 'Update'
                ? 'updateTimeSlot'
                : 'submitDeleteBlockTimeSlot'
            "
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <EventTimeSlotAttendees
      :permission="permission"
      :event-id="eventId"
      :building-id="buildingId"
      :time-slot-id="timeSlotId"
      :is-open="openTimeSlotAttendees"
      @close-dialog="closeTimeSlotAttendees"
      @added-attendee="fetchBlockTimeSlotList"
      @delete-attendee="fetchBlockTimeSlotList"
    />

    <template v-if="showWarning">
      <v-dialog
        :value="showWarning"
        @click:outside="closeWarning"
        @keydown.esc="closeWarning"
        max-width="520"
      >
        <v-card>
          <v-card-title class="headline"> Warning </v-card-title>
          <v-card-text v-if="!!bookingBlocks.length">
            <strong
              >There is one or more existing bookings during this time.</strong
            >
            <v-list-item v-for="booking in bookingBlocks" :key="booking.id">
              <v-list-item-content>
                <v-list-item-title
                  class="primary--text cursor-pointer"
                  @click="redirectBookingDetail(booking.id)"
                  >{{ `Booking ${booking.id}` }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
          <v-card-text v-else>
            <strong>This time slot has been blocked in this space.</strong>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <slot>
              <v-btn
                text
                color="green"
                class="confirm-modal__confirm"
                @click="confirmWarning"
                >Okay</v-btn
              >
            </slot>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Bookable Booking Schedule Type Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import { mdiPencil, mdiDelete, mdiPlus, mdiEyeOff, mdiEye } from '@mdi/js'
import infiniteScroll from 'vue-infinite-scroll'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import DatetimePicker from '@/components/fields/DatetimePicker'
import {
  convertToDayjs,
  dateOnlyFormat,
  timeOnlyFormat,
  dateFormat,
  combineDateAndTimeFromObject,
} from '@/utils/date'
import DATETIME_FORMAT from '@/utils/enums/DatetimeFormat'
import EventTimeSlotAttendees from './EventTimeSlotAttendees.vue'
import dayjs from 'dayjs'

export default {
  components: {
    DatetimePicker,
    EventTimeSlotAttendees,
  },

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin],

  directives: {
    infiniteScroll,
  },

  props: {
    limit: Number,
    buildingId: Number,
    permission: Boolean,
    form: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    const initForm = {
      id: '',
      description: '',
      date: new Date().toISOString(),
      start: '',
      end: '',
    }

    return {
      defaultFilter: 'name',
      sortOrder: true,
      icons: {
        edit: mdiPencil,
        delete: mdiDelete,
        add: mdiPlus,
        eyesOff: mdiEyeOff,
        eyesOn: mdiEye,
      },
      headers: [
        {
          text: 'Date',
          value: 'start_time',
          sortable: true,
        },
        {
          text: 'Start',
          value: 'start',
          sortable: false,
        },
        {
          text: 'End',
          value: 'end_time',
          sortable: false,
        },
        {
          text: 'Attendees',
          value: 'attendees',
          sortable: true,
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
      ],
      actions: [
        {
          value: 'edit',
          label: <v-icon class="mr-2">{{ mdiPencil }}</v-icon>,
        },
        {
          value: 'delete',
          label: <v-icon class="mr-2">{{ mdiDelete }}</v-icon>,
        },
      ],
      loading: false,
      blockItems: [],
      totalItems: 10,
      selectedItem: null,
      dialogBlockSlotTime: false,
      dialogDeleteBlockSlotTime: false,
      initForm,
      formBlockTimeSlot: new Form({
        id: '',
        description: '',
        date: new Date().toISOString(),
        start_time: '',
        end_time: '',
      }),
      options: {},
      blockTimeSlotMeta: 0,
      openTimeSlotAttendees: false,
      timeSlotId: null,
      eventId: null,
      timeslotDefaultFilter: 'start_time',
      newTimeSlotIsOverlap: false,
      noDataText: 'There are no time slots',
      showAllSlots: false,
      showWarningActionTimeSlot: false,
      typeActionTimeSlot: '',
      isValidTime: true,
      isValidDate: true,
      isValidUpcomingTime: true,
      bookingBlocks: [],
      blockByTimeSlot: null,
    }
  },
  mounted() {
    this.fetchBlockTimeSlotList()
  },
  watch: {
    blockTimeSlots(newValue) {
      if (newValue) {
        this.blockItems = newValue
        this.totalItems = newValue.length
      }
    },
    blockTimeSlotListMeta(newValue) {
      if (newValue) {
        this.blockTimeSlotMeta = newValue
      }
    },
    formBlockTimeSlot: {
      handler(newValue) {
        this.formBlockTimeSlot = newValue
      },
      deep: true,
    },
    showAllSlots() {
      this.fetchBlockTimeSlotList()
    },
  },
  computed: {
    ...mapState({
      timeSlotList: (state) => state.event.timeSlotList.list,
      blockTimeSlotListMeta: (state) => state.event.timeSlotList.meta,
      eventLimit: (state) => state.event.rsvp_limit,
    }),
    submitApi() {
      return this.createEventTimeSlot
    },
    isoFormat() {
      return DATETIME_FORMAT.isoDateFormat
    },

    timeZone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    showWarning() {
      return this.blockByTimeSlot || !!this.bookingBlocks.length
    },
  },
  methods: {
    ...mapActions({
      getBlockTimeSlotList: 'event/getBlockTimeSlotList',
      getAttendeesList: 'event/getAttendeesList',
      createEventTimeSlot: 'event/createEventTimeSlot',
      checkExistedTimeSlot: 'event/checkExistedTimeSlot',
      updateEventTimeSlot: 'event/updateEventTimeSlot',
      deleteEventTimeSlot: 'event/deleteEventTimeSlot',
      getLastTimeSlot: 'event/getLastTimeSlot',
      checkCreateEventTimeSlot: 'event/checkCreateEventTimeSlot',
    }),

    addDays(dateString, days) {
      const date = new Date(dateString)
      date.setDate(date.getDate() + days)
      return date.toISOString()
    },

    addHours(dateString, hours) {
      const date = new Date(dateString)
      date.setHours(date.getHours() + hours)
      return date.toISOString()
    },

    async createItem() {
      this.dialogBlockSlotTime = true
      this.selectedItem = null
      this.isValidDate = true
      this.isValidTime = true
      this.isValidUpcomingTime = true
      this.newTimeSlotIsOverlap = false

      await this.getLastTimeSlot({
        id: this.$route.params.id,
      }).then((data) => {
        if (data.data.length > 0) {
          const lastTime = data.data[0]
          this.formBlockTimeSlot = new Form({
            id: '',
            description: lastTime.description || '',
            date: this.addDays(lastTime.start_time, 7),
            start_time: this.addDays(lastTime.start_time, 7),
            end_time: this.addHours(this.addDays(lastTime.start_time, 7), 1),
          })
        } else {
          const currentDate = new Date()
          this.formBlockTimeSlot = new Form({
            id: '',
            description: '',
            date: currentDate.toISOString(),
            start_time: currentDate.toISOString(),
            end_time: this.addHours(currentDate, 1),
          })
        }
      })
    },

    editItem(item) {
      this.dialogBlockSlotTime = true
      this.selectedItem = item
      this.formBlockTimeSlot = new Form({ ...item, date: null })
      this.formBlockTimeSlot.date = this.formatDateString(
        this.formBlockTimeSlot.start_time
      )
      this.formBlockTimeSlot.start_time = this.formatDateString(
        this.formBlockTimeSlot.start_time
      )
      this.formBlockTimeSlot.end_time = this.formatDateString(
        this.formBlockTimeSlot.end_time
      )

      this.checkValidDate()
      this.checkValidTime()
      this.typeActionTimeSlot = 'Update'
    },

    formatDateString(dateString) {
      const date = new Date(dateString)
      const isoString = date.toISOString()
      return isoString.replace(/\.\d{3}Z$/, '.000Z')
    },

    deleteItem(item) {
      this.dialogDeleteBlockSlotTime = true
      this.selectedItem = item
      this.typeActionTimeSlot = 'Delete'
    },

    cancelDialog() {
      this.dialogBlockSlotTime = false
      this.selectedItem = null
    },
    cancelDialogDelete() {
      this.dialogDeleteBlockSlotTime = false
      this.selectedItem = null
    },
    convertToUTC(dateString) {
      const date = new Date(dateString)
      return date.toLocaleString('en-US', { timeZone: 'UTC' })
    },
    handleDateChange(newDate) {
      this.formBlockTimeSlot.date = newDate
      this.checkTimeSlotOverlap()
      this.checkValidDate()
    },
    handleStartChange(newTime) {
      const start = this.formBlockTimeSlot.start_time
      const end = this.formBlockTimeSlot.end_time

      if (start && end) {
        this.formBlockTimeSlot.end_time = this.endTimeByStartTime(newTime)
      }
      this.formBlockTimeSlot.start_time = newTime
      this.checkTimeSlotOverlap()
      this.checkValidTime()
    },
    handleEndChange(newTime) {
      this.formBlockTimeSlot.end_time = newTime
      this.checkTimeSlotOverlap()
      this.checkValidTime()
    },
    formatTime(time) {
      return timeOnlyFormat(time)
    },
    formatDate(date) {
      return dateOnlyFormat(date, DATETIME_FORMAT.eventDateFormat)
    },
    findMatchTime(itemBlock, field) {
      const findMatchItem = this.blockItems.find(
        (item) => item.id === itemBlock.id
      )
      if (findMatchItem) {
        return dateFormat(`${findMatchItem.date} ${findMatchItem[field]}`)
      }
    },
    openAttendeesModal(timeslot) {
      this.timeSlotId = `${timeslot.id}`
      this.eventId = this.$route.params.id
      this.openTimeSlotAttendees = true
    },
    closeTimeSlotAttendees() {
      this.openTimeSlotAttendees = false
      this.timeSlotId = null
      this.eventId = null
    },
    checkTimeSlotOverlap() {
      if (
        this.formBlockTimeSlot.date &&
        this.formBlockTimeSlot.start_time &&
        this.formBlockTimeSlot.end_time &&
        !this.selectedItem
      ) {
        this.checkExistedTimeSlotBeforeSubmit()
      } else {
        this.newTimeSlotIsOverlap = false
      }
    },

    checkValidTime() {
      // eslint-disable-next-line camelcase
      const { end_time, start_time, date } = this.formBlockTimeSlot

      // eslint-disable-next-line camelcase
      if (start_time && end_time) {
        this.isValidTime = new Date(end_time) > new Date(start_time)
      } else {
        this.isValidTime = true
      }

      // eslint-disable-next-line camelcase
      if (date && start_time) {
        this.checkValidWithDateTimeNow(start_time, date)
      } else {
        this.isValidDate = true
      }
    },

    checkValidDate() {
      // eslint-disable-next-line camelcase
      const { start_time, date } = this.formBlockTimeSlot

      if (date) {
        const currentDate = new Date().setHours(0, 0, 0, 0)
        const selectedDate = new Date(date).setHours(0, 0, 0, 0)
        this.isValidDate = selectedDate >= currentDate
        // eslint-disable-next-line camelcase
        if (start_time) {
          this.checkValidWithDateTimeNow(start_time, date)
        }
      } else {
        this.isValidDate = true
      }
    },

    // eslint-disable-next-line camelcase
    checkValidWithDateTimeNow(start_time, date) {
      // eslint-disable-next-line camelcase
      if (date && start_time) {
        const startTime = convertToDayjs(start_time)
        const now = convertToDayjs(new Date())
        const currentDate = convertToDayjs(new Date().setHours(0, 0, 0, 0))
        const selectedDate = convertToDayjs(new Date(date).setHours(0, 0, 0, 0))

        if (selectedDate.isSame(currentDate)) {
          this.isValidUpcomingTime = this.compareTimes(startTime, now)
        } else if (selectedDate.isBefore(currentDate)) {
          this.isValidUpcomingTime = false
        } else {
          this.isValidUpcomingTime = true
        }
      } else {
        this.isValidUpcomingTime = true
      }
    },

    compareTimes(date1, date2) {
      const time1 = date1.hour() * 3600 + date1.minute() * 60
      const time2 = date2.hour() * 3600 + date2.minute() * 60
      return time1 > time2
    },

    endTimeByStartTime(newStarTime) {
      const newStart = new Date(newStarTime)

      return new Date(newStart.getTime() + 3.6 * 10 ** 6).toISOString()
    },

    async checkExistedTimeSlotBeforeSubmit() {
      this.loading = true
      try {
        const startTime = combineDateAndTimeFromObject(
          this.formBlockTimeSlot.date,
          this.formBlockTimeSlot.start_time
        )
        const endTime = combineDateAndTimeFromObject(
          this.formBlockTimeSlot.date,
          this.formBlockTimeSlot.end_time
        )
        const res = await this.checkExistedTimeSlot({
          event_id: this.$route.params.id,
          date: dayjs(this.formBlockTimeSlot.date).format('YYYY-MM-DD'),
          start_time: startTime,
          end_time: endTime,
        })
        if (res.http_status === 200) {
          this.newTimeSlotIsOverlap = res.exist
        }
      } catch (err) {
        this.showSnackbar(this.getErrorMessage(err), false)
      } finally {
        this.loading = false
      }
    },

    async submitCreate() {
      this.loading = true
      this.formBlockTimeSlot.$busy = true
      try {
        const startTime = combineDateAndTimeFromObject(
          this.formBlockTimeSlot.date,
          this.formBlockTimeSlot.start_time
        )
        const endTime = combineDateAndTimeFromObject(
          this.formBlockTimeSlot.date,
          this.formBlockTimeSlot.end_time
        )
        await this.createEventTimeSlot({
          id: this.$route.params.id,
          start_time: startTime,
          end_time: endTime,
        }).then((data) => {
          this.showSnackbar('Time slot successfully created!')
        })
      } catch (err) {
        this.formBlockTimeSlot.$busy = false
        this.showSnackbar(this.getErrorMessage(err), false)
      } finally {
        this.loading = false
        this.dialogBlockSlotTime = false
        this.formBlockTimeSlot.$busy = false
        this.closeWarning()
        this.fetchBlockTimeSlotList()
      }
    },

    async checkTimeSlot() {
      this.loading = true
      this.formBlockTimeSlot.$busy = true
      try {
        const startTime = combineDateAndTimeFromObject(
          this.formBlockTimeSlot.date,
          this.formBlockTimeSlot.start_time
        )
        const endTime = combineDateAndTimeFromObject(
          this.formBlockTimeSlot.date,
          this.formBlockTimeSlot.end_time
        )
        await this.checkCreateEventTimeSlot({
          id: this.$route.params.id,
          start_time: startTime,
          end_time: endTime,
        }).then((data) => {
          if (data.block_time_slot || data.bookings.length > 0) {
            this.blockByTimeSlot = data.block_time_slot
            this.bookingBlocks = data.bookings
          } else {
            this.selectedItem ? this.updateTimeSlot() : this.submitCreate()
          }
        })
      } catch (err) {
        this.formBlockTimeSlot.$busy = false
        this.showSnackbar(this.getErrorMessage(err), false)
      } finally {
        this.loading = false
        this.formBlockTimeSlot.$busy = false
      }
    },

    async updateTimeSlot() {
      try {
        const { id } = this.selectedItem
        const startTime = combineDateAndTimeFromObject(
          this.formBlockTimeSlot.date,
          this.formBlockTimeSlot.start_time
        )
        const endTime = combineDateAndTimeFromObject(
          this.formBlockTimeSlot.date,
          this.formBlockTimeSlot.end_time
        )
        await this.updateEventTimeSlot({
          event_id: this.$route.params.id,
          id: id,
          start_time: startTime,
          end_time: endTime,
        })
        this.showSnackbar('Time slot details successfully updated!')
      } catch (err) {
        this.showSnackbar(this.getErrorMessage(err), false)
      } finally {
        this.closeWarning()
        this.dialogBlockSlotTime = false
        this.showWarningActionTimeSlot = false
        this.fetchBlockTimeSlotList()
      }
    },

    async fetchBlockTimeSlotList(options) {
      this.loading = true

      if (!options) {
        this.setSortOption()
      }

      const currentPage = options?.page || 1

      try {
        await this.getBlockTimeSlotList({
          id: this.$route.params.id,
          page: currentPage,
          sort: options?.sortBy[0]
            ? options.sortDesc[0]
              ? '-' + options.sortBy[0]
              : options.sortBy[0]
            : '-start_time',
          filterPast: this.showAllSlots,
        })
      } catch (err) {
        this.showSnackbar(this.getErrorMessage(err), false)
      } finally {
        this.loading = false
      }
    },

    async submitDeleteBlockTimeSlot() {
      const { id } = this.selectedItem
      this.loading = true
      try {
        await this.deleteEventTimeSlot({
          event_id: this.$route.params.id,
          id: id,
        })
      } catch (err) {
        this.showSnackbar(this.getErrorMessage(err), false)
      } finally {
        this.loading = false
        this.dialogDeleteBlockSlotTime = false
        this.showWarningActionTimeSlot = false
        this.fetchBlockTimeSlotList()
      }
    },

    toggleShowHidePastSlots() {
      return (this.showAllSlots = !this.showAllSlots)
    },

    handleShowWarningActionTimeSlot() {
      const { attendees } = this.selectedItem
      if (attendees > 0) {
        this.showWarningActionTimeSlot = true
        this.dialogBlockSlotTime = false
        this.dialogDeleteBlockSlotTime = false
        return
      }
      if (this.typeActionTimeSlot === 'Update') {
        this.checkTimeSlot()
      } else {
        this.submitDeleteBlockTimeSlot()
      }
    },
    handleHiddenWarningActionTimeSlot() {
      return (this.showWarningActionTimeSlot = false)
    },

    setSortOption() {
      this.timeslotDefaultFilter = 'start_time'
      this.sortOrder = true
    },

    closeWarning() {
      this.blockByTimeSlot = null
      this.bookingBlocks = []
      this.loading = false
    },

    redirectBookingDetail(id) {
      const routeData = this.$router.resolve({
        name: 'booking.details',
        params: { id },
      })
      window.open(routeData.href, '_blank')
    },

    confirmWarning() {
      return this.selectedItem ? this.updateTimeSlot() : this.submitCreate()
    },
  },
}
</script>

<style lang="scss" scoped>
.show-hide-slots-btn {
  display: flex;
  margin: 30px 0 0 auto;
}

.delete-attendee-warning {
  font-size: 18px;
}
</style>
